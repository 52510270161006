header{
	background-color: $bg-grey;
	height: 90px;
	@include media-breakpoint-down(md) {
		height: 70px;
	}
	.interior &{
		background-color: $bg-grey;
	}
	.planes.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.centros.interior &{
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 10;
	}
	.logo{
		@include media-breakpoint-down(md) {
			width: 190px;
		}
		@include media-breakpoint-down(sm) {
			width: 150px;
		}
	}

	.menu{
		padding:0;
		text-align: center;
		li{
			margin-left: 10px;
			list-style:none;
			display: inline-block;
			@include fontSize($body-size-s);
			font-family: 'UFC Sans Medium';
			a{
				color:#FCFCFC;
				&:hover{
					@extend .fuente-title;
					color:#d20a0a;
					text-decoration: underline;
				}
			}
			&.active{
				@extend .fuente-title;
				a{

					text-decoration: none;
					color:#FCFCFC;
					;
				}
			}
		}
	}

}
