// clases de texto
.fuente-title {
	font-family: "UFC Sans Bold";
}
.fuente-body {
	font-family: "UFC Sans Light";
}
.color-primary {
	color: $color-primary;
}
.color-secondary {
	color: $color-secondary;
}
.color-txtgrey {
	color: $txt-grey;
}
.color-negro {
	color: $negro;
}

.title-xxl {
	@extend .fuente-title;
	@include fontSize($titulo-size-xxl);
	@include media-breakpoint-down(md) {
		@include fontSize($titulo-size-l);
	}
}
.title-xl {
	@extend .fuente-title;
	@include fontSize($titulo-size-xl);
	@include media-breakpoint-down(md) {
		@include fontSize($titulo-size-m);
	}
}
.title-l {
	@extend .fuente-title;
	@include fontSize($titulo-size-l);
}
.title-m {
	@extend .fuente-title;
	@include fontSize($titulo-size-m);
}
.title-s {
	@extend .fuente-title;
	@include fontSize($titulo-size-s);
	@include media-breakpoint-down(md) {
		@include fontSize($titulo-size-xxs);
	}
}
.title-xs {
	@extend .fuente-title;
	@include fontSize($titulo-size-xs);
}
.title-xxs {
	@extend .fuente-title;
	@include fontSize($titulo-size-xxs);
}
.title-xxxs {
	@extend .fuente-title;
	@include fontSize($titulo-size-xxxs);
}

.body-xxl {
	@extend .fuente-body;
	@include fontSize($body-size-xxl);
}
.body-xl {
	@extend .fuente-body;
	@include fontSize($body-size-xl);
}
.body-l {
	@extend .fuente-body;
	@include fontSize($body-size-l);
}
.body-m {
	@extend .fuente-body;
	@include fontSize($body-size-m);
}
.body-s {
	@extend .fuente-body;
	@include fontSize($body-size-s);
}
.body-xs {
	@extend .fuente-body;
	@include fontSize($body-size-xs);
}

.title-nobold {
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $blanco;
	margin-bottom: 0;
}
p {
	margin-bottom: 0;
	a {
		color: $txt-grey;
		text-decoration: underline;
	}
}

strong {
	@extend .fuente-title;
}

:focus {
	outline: 0;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($negro, 0.55);
}

.borde-y-negro {
	border-top: solid 2px $negro;
	border-bottom: solid 2px $negro;
}

.borde-negro {
	border: solid 2px $negro;
}

.borde-bottom-negro {
	border-bottom: solid 2px $negro;
}

/* FORMULARIOS */

input.form-control {
	border: none;
	border-radius: 0;
	height: 50px;
	text-align: center;
	@include fontSize($body-size-m);
	.has-error & {
		border: 1px solid $error;
	}
}
select.form-control {
	width: 100%;
	height: 38px;
	border: none;
	background-color: #ebebeb;
	border: 1px solid #ebebeb;
	text-align: left;
	font-family: "UFC Sans Light";
	option {
		font-family: "UFC Sans Light";
	}
}
.class_option {
	font-family: "UFC Sans Light" !important;
}
.btn {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.btn-link {
	color: $txt-grey;
	text-decoration: underline;
}

.btn-primary {
	font-family: 'UFC Sans Medium';
	background: rgb(210,10,10);
	background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
	background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
	background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
	@include fontSize($body-size-l);
	color:#FCFCFC!important;
	border: none;
	border-radius: 0;
	height: 50px;
	text-align: center;
	position: relative;
	@include transition(all 0.4s ease-out);
	&:hover {
		background: rgb(210,10,10);
		background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
		background-color: #fff!important;
		opacity:0.8!important;
	}
	&.disabled {
		background-color: $blanco;
		color: $negro;
		opacity: 1;
		pointer-events: all;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
		}
	}
}

.btn-secondary {
	@extend .fuente-title;
	background-color: $rosa;
	@include fontSize($body-size-l);
	color: $blanco !important;
	border: none;
	border-radius: 0;
	height: 50px;
	line-height: 41px;
	text-align: center;
	position: relative;
	color: #FCFCFC !important;
	background-color: #9E0808!important;
	&.disabled {
		background-color: $negro;
		color: $blanco;
		opacity: 1;
		pointer-events: all;
	}
	&:hover {
		color: #9E0808 !important;
		background-color: #fff!important;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
			@include filter(invert(100%));
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
	}

	&.btn-xs {
		@include fontSize(12px);
		height: 27px;
		line-height: 23px;
		padding: 2px 20px;
		position: absolute;
		top: -2px;
		white-space: nowrap;
		// right: 0px;/*-140*/
	}
}
.btn-negro {
	@extend .fuente-title;
	background-color: $negro;
	@include fontSize($body-size-l);
	color: $blanco !important;
	border: none;
	border-radius: 0;
	height: 50px;
	line-height: 41px;
	text-align: center;
	position: relative;
	&.disabled {
		background-color: $negro;
		color: $blanco;
		opacity: 1;
		pointer-events: all;
	}
	&:hover {
		background-color: $txt-grey !important;
		color: $negro;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
			@include filter(invert(100%));
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
	}

	&.btn-xs {
		@include fontSize(12px);
		height: 27px;
		line-height: 23px;
		padding: 2px 30px;
		position: absolute;
		top: -2px;
		white-space: nowrap;
		// right: 0px;/*JC quito -140*/
	}
}
.btn-skew {
	// transform: skewX(-15deg);
	// background-color: $negro;
	// border-radius: 0;
	// margin-top: 30px;
	// margin-bottom: 60px;
	padding: 7px 90px;
	// color: $blanco;
	// font-family: "UFC Sans Bold";
	// span {
	// 	transform: skewX(15deg);
	// 	display: block;
	// }
	// &:hover {
	// 	background-color: $txt-grey;
	// 	color: $blanco;
	// }
	&.btn-skew-small {
		padding: 5px 40px;
	}
	font-family: 'UFC Sans Medium'!important;
	background: rgb(210,10,10);
	background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
	background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
	background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
	@include fontSize($body-size-l);
	color:#FCFCFC!important;
	border: none;
	border-radius: 0;
	height: 50px;
	text-align: center;
	position: relative;
	@include transition(all 0.4s ease-out);
	p{
		font-family: 'UFC Sans Medium'!important;
		color:#FCFCFC!important;
	}
	&:hover {
		background: rgb(210,10,10);
		background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
		background-color: #fff!important;
		opacity:0.8!important;
	}
}

.bg-gris {
	background-color: $bg-grey;
}
.bg-gris-oscuro {
	background-color: #d2d2d2;
}
.btn-terciary {
	font-family: "UFC Sans Regular";
	background-color: $bg-grey;
	@include fontSize(15px);
	color: $negro;
	border-radius: 0;
	height: 26px;
	line-height: 1em;
	text-align: center;
	transition: color 0.3s;
	position: relative;
	margin-left: 0 !important;
	@include media-breakpoint-down(md) {
		@include fontSize(14px);
		padding: 0 5px;
		line-height: 23px;
		height: 23px;
	}
	&:hover,
	&.active {
		background-color: $gris-hover !important;
		color: $blanco;
	}

	&.disabled {
		background-color: $bg-grey;
		color: $negro;
		opacity: 1;
		pointer-events: all;
	}
	&.loader {
		color: transparent;
		pointer-events: none;
		&:after {
			content: " ";
			background-image: url(../images/spinner.svg);
			background-size: 22px;
			width: 22px;
			height: 22px;
			position: absolute;
			left: 50%;
			margin-left: -11px;
			top: 50%;
			margin-top: -11px;
			@include giroinfinito(1.5s);
		}
	}

	&.btn-xl {
		width: 400px;
		max-width: 100%;
	}

	&.btn-xs {
		@include fontSize($body-size-xs);
		height: 33px;
		line-height: 24px;
	}
}
select:not(.js-idiomas) {
	@include media-breakpoint-down(md) {
		@include fontSize(16px);
		width: calc(65vw - 50px) !important;
	}
}
.selector{
	background-color: transparent;
	position: absolute;
	top: -35px;
	right: 15px;
	z-index: 1050;
	@include media-breakpoint-down(sm) {
		position: inherit;
		top: 0;
		right: 0;
	}
	@include media-breakpoint-down(sm) {
		background-color: #ebebeb;
	}
	select.js-idiomas {

	}
}


// .btn-secondary{

// 	border-radius: 0px;

// 	&.btn-xl{

// 		@extend .fuente-title;
// 		@include fontSize($body-size-l);
// 		color:$blanco;
// 		line-height: 43px;
// 		background-color: $txt-grey;
// 		border: none;
// 		border-radius: 0;
// 		height: 50px;
// 		text-align: center;
// 		transition:color 0.3s;
// 		position:relative;
// 		width: 400px;
// 		max-width: 100%;
// 		&:hover{
// 			background-color:$blanco!important;
// 			color:$negro;
// 		}

// 	}

// }

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.tooltip {
	font-size: 10px;
	.arrow::before {
		border-right-color: $blanco;
	}

	.tooltip-inner {
		background-color: $blanco;
		color: $negro;
	}
}

.tooltip-inner {
	text-align: left;
	p {
		text-indent: -8px;
		padding-left: 10px;
	}
}

.alert {
	display: none;
	padding: 5px 15px;
	margin-top: 10px;
	border-radius: 0;
	.mensaje-alerta {
		font-weight: 700;
		color: #000;
		font-size: 1rem;
		display: inline-block;
		margin-top: 20px;
		ul {
			list-style: none;
			padding-left: 15px;
			margin-bottom: 10px;
			li {
				font-weight: 300;
				color: #000;
				font-size: 0.8rem;
				&:before {
					content: "\f057";
					font-family: FontAwesome;
					font-size: 0.8rem;
					color: red;
					margin-right: 5px;
				}
			}
		}
	}
}
.has-error {
	.campo,
	textarea,
	select {
		border: 2px solid #c60000 !important;
	}
	.chosen-container {
		.chosen-single {
			border: 2px solid #c60000 !important;
		}
	}
	.icheckbox_square-green {
		background-position: -28px 0;
	}
	.checkbox {
		&:before {
			border: 2px solid #c60000 !important;
		}
	}
}

.modal-title {
	color:#D20A0A!important;
	font-family: 'UFC Sans Medium';
	margin-left: auto;
}
.modal-body{
	color:$negro;
	font-family: 'Roboto';
	font-weight: normal;
	font-style: normal;
	@include fontSize(16px);
	text-align:center;
}
.up {
	display: block;
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 99999;
}

.embed-responsive-4by3::before {
	padding-top: 62.5%;
}

.alert.fade {
	opacity: 0;
	transition: opacity 0.2s linear;
	&.show {
		opacity: 1;
		display: block;
	}
}
.alert-dismissible .close {
	padding: 5px 15px 4px;
}
