footer{
	padding:25px 0 25px;
	position:relative;
	@include media-breakpoint-down(md) {
		padding:0px;
	}	
	@include media-breakpoint-down(sm) {
		padding-bottom: 20px;
		padding-top: 20px;
	}	
	background-color: $bg-grey;
	margin-bottom: 0px;
	.p-tlc{
		position: absolute;
		bottom:15px;
		right:15px;
		width: 150px;
		@include media-breakpoint-down(sm) {
			position: relative;
			margin-top:30px;
			margin-left: auto;
			margin-right: auto;
			right:unset;
			width: 125px;
		}
	}
	.cont-logo-cliente{
		img{
			@include media-breakpoint-down(sm) {	
				width: 225px!important;
				height: auto;
			}
		}
	}
	.iconos-redes{
		border-bottom: 1px solid white;
		padding-bottom:30px;
		padding-left:0px;
		margin:12px 0 0; 
		@media (max-height: 1000px) {
			/*margin:20px 0 0;*/
		}
		li{
			margin:0 30px 0 0px;
			display: inline;
			@include media-breakpoint-down(md) {
				margin:2px 8px;
			}
			img{
				height: 40px!important;
				// @media (max-height: 1000px) {
				// 	/*height: 50px;*/
				// }
				// @include media-breakpoint-down(md) {
				// 	height: 50px!important;
				// }
				@include media-breakpoint-down(md) {
					height: 30px!important;
				}
				@include media-breakpoint-down(sm) {
					height: 25px!important;
				}
				@include media-breakpoint-down(xs) {
					margin-top: 25px;
				}
			}
			&:first-child{
				img{
					@include media-breakpoint-down(xs) {
						margin-top: 0px;
					}
				}
			}
			&:nth-child(3){
				img{
					@include media-breakpoint-down(xs) {
						margin-top: 0px;
					}
				}
			}
		}
	}
	.menu{
		padding:15px 0;
		text-align: center;
		position: relative;
		@include media-breakpoint-down(md) {
			margin-bottom: 45px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom:10px;
		}
		li{
			list-style: none;
			display: inline-block;
			@include fontSize($body-size-xs);
			@extend .fuente-title;
			font-family: 'UFC Sans Regular';
			color:#FCFCFC;
			margin-right:30px;
			a{
				text-transform: uppercase;
				font-family: 'UFC Sans Regular';
				color:#FCFCFC;
				&:hover{
					color:#d20a0a;
					text-decoration: none;
				}
			}
			&:after{
				content:"|";
				margin:0 5px;
				display:none;
			}
			&:last-child{
				margin-right:0px;
				@include media-breakpoint-down(lg) {
					display: unset;
					margin-top: 15px;
				}
				@include media-breakpoint-down(sm) {
					display:block;
				}
				&:after{
					content:none;
					margin:0;
				}
			}

			/*cambio boton contacta para mobile*/
			.btn-secondary.btn-xs{
				position: relative!important;
				line-height: unset!important;
				padding:5px 20px!important;
				background: rgb(210,10,10);
				background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
				color:#FCFCFC!important;
				@include transition(all 0.4s ease-out);
				&:hover{
					opacity:0.8!important;
					color:#FCFCFC!important;
				}
			}
		}
	}
	.text-footer{
		@include fontSize(13px);
		line-height:1.6em;
		a{
			color:#9d9c9c;
			text-decoration: underline;
		}
	}
}
body{
	&.interior{
		.iconos-redes{
			display:none!important;
		}
	}
}



.modal{
	.modal-content{
		.modal-body{
				.form-group{
					position:relative;

					input{
						width: 100%;
						height: 38px;
						border:none;
						background-color: $bg-grey;
						border:solid 1px $bg-grey;
						text-align: left;
					}

					textarea{
						width: 100%;
						height: 38px;
						border:none;
						background-color: $bg-grey;
						border:solid 1px $bg-grey;
						text-align: left;
						height: 215px;
					}
					

					&.has-error{

						input{
							border:solid 1px red;
						}

						textarea{
							border:solid 1px red !important;
						}
					}

				}

				.cajaCheck input[type="checkbox"]:checked,
				.cajaCheck input[type="checkbox"]:not(:checked) {
				  position: absolute;
				  left: -9999px;
				  opacity:0;
				}

				.cajaCheck input[type="checkbox"]:checked + label,
				.cajaCheck input[type="checkbox"]:not(:checked) + label
				{
				  position: relative;
				  padding-left: 28px;
				  cursor: pointer;
				  line-height: 20px;
				  display: inline-block;
				  color: $negro;
				}
				.cajaCheck input[type="checkbox"]:checked + label:before,
				.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
				  content: '';
				  position: absolute;
				  left: 0;
				  top: 0;
				  width: 18px;
				  height: 18px;
				  border: 2px solid $negro;
				}
				.cajaCheck input[type="checkbox"]:checked + label:after,
				.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
				  content: '';
				  width: 18px;
				  height: 18px;
				  background: #D20A0A;
				  position: absolute;
				  top: 0px;
				  left: 0px;
				  -webkit-transition: all 0.2s ease;
				  transition: all 0.2s ease;
				}
				.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
				  opacity: 0;
				  -webkit-transform: scale(0);
				  transform: scale(0);
				}
				.cajaCheck input[type="checkbox"]:checked + label:after {
				  opacity: 1;
				  -webkit-transform: scale(1);
				  transform: scale(1);
				}

				a{
					text-decoration: underline;
					color:$negro;
				}

		}

	}

}


#modal-cookies{
	display: none;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;
	z-index: 100;
	background-color: $blanco;
	position: fixed;
	-webkit-box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	@include media-breakpoint-down(sm) {
		padding: 0 0 20px;
	}
	a{
		text-decoration: underline;
	}
	.btn-secondary.btn-xs{
		@include media-breakpoint-down(sm) {
			position: relative;
			left: 0;
		}
	}
}

#modal-registro{

	.modal-dialog{
		max-width: 1100px;
	}

	.modal-content{
		width: 1100px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}
		h2{
			font-family: 'UFC Sans Medium';
			span{
				color:#D20A0A!important;
				font-family: 'UFC Sans Medium';
			}
		}
		.body-m{
			font-family: 'Roboto';
			font-weight: normal;
    		font-style: normal;
		}
		.modal-header{
			border-bottom: none;
			
			.modal-title{
				// background-color: $negro;

				span{
					// background-color: $blanco;
				}
			}

		}

		.modal-body{
			@include media-breakpoint-down(xs) {
				padding-left:0!important;
				padding-right:0px!important;
			}
			#alerta-errores-registro{
				display: none;
			}
			label{
				color:$negro;
				font-family: 'Roboto Medium';
				font-style: normal;
				&:not(.checkbox){
					padding-left: 12px;
				}
				&.checkbox{
					font-family: 'Roboto';
					font-weight: normal;
					font-style: normal;
					@include fontSize(14px);
					a{
						font-family: 'Roboto Medium';
						font-style: normal;
					}
				}
			}
			.cuadro-txt-legal{
				color:$negro;
				font-family: 'Roboto';
				font-weight: normal;
				font-style: normal;
			}
			h3{
				color:$negro;
				font-family: 'Roboto Medium';
				font-style: normal;
			}
			p{
				strong{
					font-family: 'Roboto Medium';
					font-style: normal;
				}
			}
			.form-group{
				input, select{
					background-color: #ebebeb!important;
					font-family: 'Roboto';
					font-weight: normal;
					font-style: normal;
					border:none;
					@include fontSize(16px);
				}
			}
			#btn-enviar{
				font-family: 'UFC Sans Medium';
				background: rgb(210,10,10);
				background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
				@include fontSize($body-size-l);
				color:#FCFCFC!important;
				border: none;
				border-radius: 0;
				height: 50px;
				text-align: center;
				position: relative;
				@include transition(all 0.4s ease-out);
				&:hover {
					background: rgb(210,10,10);
					background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
					background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
					background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
					background-color: #fff!important;
					opacity:0.8!important;
				}
			}
		}

	}

}


#modal-validado{

	.modal-dialog{
		max-width: 745px;
	}

	.modal-content{
		width: 745px;
		max-width: 90vw;

		.modal-header{
			border-bottom: none;
		
			.modal-title{

				span{
					background-color: $blanco;
				}
			}

		}
		h2{
			color:#D20A0A!important;
			font-family: 'UFC Sans Medium';
		}
		p{
			font-family: 'Roboto';
			font-weight: normal;
			font-style: normal;
			color:$negro;
		}
	}
	#btn-validado{
		font-family: 'UFC Sans Medium'!important;
		background: rgb(210,10,10);
		background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
		@include fontSize($body-size-l);
		color:#FCFCFC!important;
		border: none;
		border-radius: 0;
		height: 50px;
		text-align: center;
		position: relative;
		@include transition(all 0.4s ease-out);
		p{
			font-family: 'UFC Sans Medium'!important;
			color:#FCFCFC!important;
		}
		&:hover {
			background: rgb(210,10,10);
			background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
			background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
			background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
			background-color: #fff!important;
			opacity:0.8!important;
		}
	}
}

#modal-descargabono{

	.modal-dialog{
		max-width: 1100px;
	}

	.modal-content{
		width: 1100px;
		max-width: 90vw;
		// max-height: 90vh;
		// overflow:auto;
		margin-left: auto;
		margin-right: auto;
		
		.modal-header{
			border-bottom: none;
			z-index: 5;
			padding: 50px 115px 0;
			@include media-breakpoint-down(sm) {
				padding: 50px 15px 0;
			}
			.modal-title{

				span{
					background-color: $blanco;
				}
			}
			h2{
				font-family: 'UFC Sans Medium';
				span{
					color:#D20A0A!important;
					font-family: 'UFC Sans Medium';
				}
			}
			.close{
				opacity:1;
				span{
					position: absolute;
				    top: 15px;
				    right: 15px;
				    img{
				    	height: 30px;
						width: 30px;
						@include media-breakpoint-down(xs) {
							height: 15px;
							width: 15px;
						}
				    }
				}
			}		
		}

		.modal-body{
			padding: 0px 115px 50px;
			@include media-breakpoint-down(sm) {
				padding: 0px 15px 50px;
			}
			h2{
				color:$negro;
				font-family: 'Roboto';
				font-weight: normal;
				font-style: normal;
				@include fontSize(16px);
			}
			#alerta-errores-descargabono{
				display: none;
			}
			label{
				font-family: 'Roboto Medium';
				font-style: normal;
			}
			.form-group{
				input, select{
					background-color: #ebebeb!important;
					font-family: 'Roboto';
					font-weight: normal;
					font-style: normal;
					border:none;
					@include fontSize(16px);
				}
			}
			#btn-enviar-bono{
				font-family: 'UFC Sans Medium'!important;
				background: rgb(210,10,10);
				background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
				@include fontSize($body-size-l);
				color:#FCFCFC!important;
				border: none;
				border-radius: 0;
				height: 50px;
				text-align: center;
				position: relative;
				@include transition(all 0.4s ease-out);
				p{
					font-family: 'UFC Sans Medium'!important;
					color:#FCFCFC!important;
				}
				&:hover {
					background: rgb(210,10,10);
					background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
					background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
					background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
					background-color: #fff!important;
					opacity:0.8!important;
				}
			}

		}

	}

}

#modal-bonook{

	.modal-dialog{
		max-width: 745px;
	}

	.modal-content{
		width: 745px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}

		.modal-header{
			border-bottom: none;
		
			.modal-title{

				span{
					background-color: $blanco;
				}
			}

		}
		.btn-secondary{
			font-family: 'UFC Sans Medium'!important;
			background: rgb(210,10,10);
			background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
			background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
			background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
			@include fontSize($body-size-l);
			color:#FCFCFC!important;
			border: none;
			border-radius: 0;
			height: 50px;
			text-align: center;
			position: relative;
			@include transition(all 0.4s ease-out);
			p{
				font-family: 'UFC Sans Medium'!important;
				color:#FCFCFC!important;
			}
			&:hover {
				background: rgb(210,10,10);
				background: -moz-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: -webkit-linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				background: linear-gradient(270deg, rgba(210,10,10,1) 0%, rgba(158,8,8,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d20a0a",endColorstr="#9e0808",GradientType=1);
				background-color: #fff!important;
				opacity:0.8!important;
			}
		}

	}

}
