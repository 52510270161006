@font-face {
    font-family: 'UFC Sans Regular';
    src: url('../fonts/UFCSans-Regular.eot');
    src: url('../fonts/UFCSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-Regular.woff2') format('woff2'),
        url('../fonts/UFCSans-Regular.woff') format('woff'),
        url('../fonts/UFCSans-Regular.ttf') format('truetype'),
        url('../fonts/UFCSans-Regular.svg#UFCSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans';
    src: url('../fonts/UFCSans-RegularOblique.eot');
    src: url('../fonts/UFCSans-RegularOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-RegularOblique.woff2') format('woff2'),
        url('../fonts/UFCSans-RegularOblique.woff') format('woff'),
        url('../fonts/UFCSans-RegularOblique.ttf') format('truetype'),
        url('../fonts/UFCSans-RegularOblique.svg#UFCSans-RegularOblique') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Light Oblique';
    src: url('../fonts/UFCSans-LightOblique.eot');
    src: url('../fonts/UFCSans-LightOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-LightOblique.woff2') format('woff2'),
        url('../fonts/UFCSans-LightOblique.woff') format('woff'),
        url('../fonts/UFCSans-LightOblique.ttf') format('truetype'),
        url('../fonts/UFCSans-LightOblique.svg#UFCSans-LightOblique') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Medium';
    src: url('../fonts/UFCSans-Medium.eot');
    src: url('../fonts/UFCSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-Medium.woff2') format('woff2'),
        url('../fonts/UFCSans-Medium.woff') format('woff'),
        url('../fonts/UFCSans-Medium.ttf') format('truetype'),
        url('../fonts/UFCSans-Medium.svg#UFCSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Black';
    src: url('../fonts/UFCSans-Black.eot');
    src: url('../fonts/UFCSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-Black.woff2') format('woff2'),
        url('../fonts/UFCSans-Black.woff') format('woff'),
        url('../fonts/UFCSans-Black.ttf') format('truetype'),
        url('../fonts/UFCSans-Black.svg#UFCSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Light';
    src: url('../fonts/UFCSans-Light.eot');
    src: url('../fonts/UFCSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-Light.woff2') format('woff2'),
        url('../fonts/UFCSans-Light.woff') format('woff'),
        url('../fonts/UFCSans-Light.ttf') format('truetype'),
        url('../fonts/UFCSans-Light.svg#UFCSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Bold Oblique';
    src: url('../fonts/UFCSans-BoldOblique.eot');
    src: url('../fonts/UFCSans-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-BoldOblique.woff2') format('woff2'),
        url('../fonts/UFCSans-BoldOblique.woff') format('woff'),
        url('../fonts/UFCSans-BoldOblique.ttf') format('truetype'),
        url('../fonts/UFCSans-BoldOblique.svg#UFCSans-BoldOblique') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Bold';
    src: url('../fonts/UFCSans-Bold.eot');
    src: url('../fonts/UFCSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-Bold.woff2') format('woff2'),
        url('../fonts/UFCSans-Bold.woff') format('woff'),
        url('../fonts/UFCSans-Bold.ttf') format('truetype'),
        url('../fonts/UFCSans-Bold.svg#UFCSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Black Oblique';
    src: url('../fonts/UFCSans-BlackOblique.eot');
    src: url('../fonts/UFCSans-BlackOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-BlackOblique.woff2') format('woff2'),
        url('../fonts/UFCSans-BlackOblique.woff') format('woff'),
        url('../fonts/UFCSans-BlackOblique.ttf') format('truetype'),
        url('../fonts/UFCSans-BlackOblique.svg#UFCSans-BlackOblique') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'UFC Sans Medium Oblique';
    src: url('../fonts/UFCSans-MediumOblique.eot');
    src: url('../fonts/UFCSans-MediumOblique.eot?#iefix') format('embedded-opentype'),
        url('../fonts/UFCSans-MediumOblique.woff2') format('woff2'),
        url('../fonts/UFCSans-MediumOblique.woff') format('woff'),
        url('../fonts/UFCSans-MediumOblique.ttf') format('truetype'),
        url('../fonts/UFCSans-MediumOblique.svg#UFCSans-MediumOblique') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Bold';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Bold Italic';
    src: url('../fonts/Roboto-BoldItalic.eot');
    src: url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Black';
    src: url('../fonts/Roboto-Black.eot');
    src: url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Black Italic';
    src: url('../fonts/Roboto-BlackItalic.eot');
    src: url('../fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Medium Italic';
    src: url('../fonts/Roboto-MediumItalic.eot');
    src: url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Medium';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic.eot');
    src: url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff'),
        url('../fonts/Roboto-Italic.ttf') format('truetype'),
        url('../fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-ThinItalic.eot');
    src: url('../fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-LightItalic.eot');
    src: url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Thin.eot');
    src: url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff'),
        url('../fonts/Roboto-Thin.ttf') format('truetype'),
        url('../fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}





@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fontawesome-webfont.woff2') format('woff2'),
        url('../fonts/fontawesome-webfont.woff') format('woff'),
        url('../fonts/fontawesome-webfont.ttf') format('truetype'),
        url('../fonts/fontawesome-webfont.svg#fontawesome-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}
