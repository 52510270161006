body.lang-ar {
	text-align: right;
	header{
		direction: ltr;
	}
	/*
	#section-home {
		.input-group .input-group-append{
			.ico-info{
				margin-left: inherit !important;
				margin-right: 20px !important;
			}
		}
		.btn-primary {
			width: calc(100% - 44px);
			margin-right: 0;
			margin-left: 45px !important;
		}
	}
	*/
	#section-contacta{
		padding-top: 20px;
		.contenedor-contacta h1 {
			padding-left: 45px;
			position: relative;
		}
		.texto,
		.form-group textarea,
		.form-group input{
			text-align: right !important;
		}
	}
	.text-left {
		text-align: inherit !important;
	}
	select.form-control{
		text-align: right;
	}
	#section-centros .encabezado .contendor-titulo .pre-titulo {
		margin-left: auto;
		margin-right: initial;
		.titulo{
			text-align: left;
		}
	}
	footer .menu{
		direction: rtl;
		li:before{
			content: "|";
    		margin: 0 5px;
		}
		li:first-child:before,
		li:last-child:before,
		li:after {
			display: none;
		}
	}
}
#section-centros .encabezado .contendor-titulo .pre-titulo {
	margin-right: auto;
	margin-left: initial;
}